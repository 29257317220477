import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import "./static-img.css";

export default function StaticImg() {
  const data = useStaticQuery(graphql`
    query {
      file {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <div className="container-static-img">
    <GatsbyImage
      image={data.file.childImageSharp.gatsbyImageData}
      alt="A man in a hat in the meadow"
    />
    </div>
  );
}
